<template>
  <v-container fluid class="mb-10">
    <v-row class="mx-auto" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-tabs
            background-color="#1e6f5c"
            active-class="white black--text"
            height="60"
            dark
          >
            <v-tab>Pending Delivery</v-tab>
            <v-tab>Picked Delivery</v-tab>
            <v-tab>Completed Delivery</v-tab>

            <!-- THIS TAB IS FOR PENDING DELIVERY LIST -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="pendingDelivery"
                        :items-per-page="10"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        class="elevation-0"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mr-2"
                                v-on="on"
                                v-bind="attrs"
                                @click="showDetails(item.invoice_id)"
                              >
                                mdi-pencil
                              </v-icon>
                              <v-btn @click="pick_order(item.id)" v-if="item.delivery_status = 'Pending'" dark small color="#495464" class="mx-2">PICK</v-btn>
                              <v-btn v-if="item.delivery_status != 'Pending'" dark small class="green">CONFIRMED</v-btn>
                            </template>
                            <span>Show Details</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- THIS TAB IS FOR PICKED ORDERS -->
             <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="pickedDelivery"
                        :items-per-page="10"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        class="elevation-0"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mr-2"
                                v-on="on"
                                v-bind="attrs"
                                @click="showDetails(item.id)"
                              >
                                mdi-pencil
                              </v-icon>
                              <!-- <v-btn @click="change_order_status(item.id)" v-if="item.delivery_status = 'Pending'" dark small color="#495464" class="mx-2">PICK</v-btn> -->
                              <v-btn @click="sendOtp(item.id, item.phone_number); start()" dark small color="#91c788">COMPLETE</v-btn>
                            </template>
                            <span>Show Details</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

            <!-- THIS IS COMPLETED TAB -->
            <v-tab-item>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-card class="my-5" elevation="0">
                      <v-data-table
                        :headers="headers"
                        :items="completedDelivery"
                        :items-per-page="10"
                        :loading="loading"
                        loading-text="Loading... Please wait"
                        class="elevation-0"
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="mr-2"
                                v-on="on"
                                v-bind="attrs"
                                @click="showDetails(item.invoice_id)"
                              >
                                mdi-pencil
                              </v-icon>
                              <!-- <v-btn @click="change_order_status(item.id)" v-if="item.delivery_status = 'Pending'" dark small color="#495464" class="mx-2">PICK</v-btn> -->
                              <!-- <v-btn v-if="item.delivery_status != 'Pending'" dark small class="green">CONFIRMED</v-btn> -->
                            </template>
                            <span>Show Details</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>

          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

     <v-snackbar
      v-model="snackbar1"
      :timeout="timeout"
    >
      Something Went Wrong!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar1 = false"
        >
          Close
        </v-btn>
      </template>
     </v-snackbar>

      <v-snackbar
      v-model="otpErrorSnackBar"
      :timeout="timeout"
    >
      Invalid OTP
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="otpErrorSnackBar = false"
        >
          Close
        </v-btn>
      </template>
     </v-snackbar>

      <v-snackbar
      v-model="otpSuccessSnackBar"
      :timeout="timeout"
    >
      Successful OTP
      <template v-slot:action="{ attrs }">
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="otpSuccessSnackBar = false"
        >
          Close
        </v-btn>
      </template>
     </v-snackbar>

     <v-dialog v-model="showOtpModal" width="400" :persistent="time > 0">
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn> showPaymentMethods = true
        @click="otpOpenModal"-->
      </template>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          <p class="mb-0 text-body-1" v-if="time > 0">
            Enter your OTP sent to
            <span class="text-h6">{{ user_phone_number }}</span>
          </p>
          <p class="mb-0 text-body-1" v-else>Time Up !!</p>
        </v-card-title>

        <div v-if="time > 0">
          <v-card-text class="px-3 pb-0">
            <v-form>
              <v-row align="center" justify="center" class="mt-5">
                <!-- <div
              class="d-flex flex-row justify-space-between mx-2"
                v-for="(n, index) in otpArray"
                :key="index"
              >
                <v-text-field v-model="otpArray[index]" solo  style="max-width: 50px"></v-text-field>
              </div> -->

                <v-text-field
                  label="M-"
                  solo
                  disabled
                  style="max-width: 50px"
                  class="ml-2"
                ></v-text-field>
                <v-text-field
                  v-model="otp"
                  :rules="[rules.required, rules.counter]"
                  outlined
                  style="max-width: 150px"
                  height="48px"
                  class="ml-3"
                ></v-text-field>

                <!-- <v-row
                  ><v-col v-for="(n, index) in otpArray" :key="index">
                    <v-text-field
                      v-model="otpArray[index]"
                      solo
                      style="max-width: 50px"
                    ></v-text-field> </v-col
                ></v-row> -->
              </v-row>
            </v-form>
            <div class="timer d-flex flex-row">
              <p class="font-weight-bold">Time Left :</p>
              <span class="ml-2">{{ prettyTime | prettify }}</span>
            </div>
            <!-- <button @click="start">Start</button> -->
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#FF7743"
              @click="confirmOtp()"
              dark
              elevation="0"
              style="min-width: 100px"
            >
              Verify
            </v-btn>
          </v-card-actions>
        </div>
        <!-- <v-card-text class="pa-5 text-center" v-else>
          <v-btn @click="otpOpenModal" color="red" text outlined
            >Resend OTP</v-btn
          >
        </v-card-text> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    snackbar1: false,
    timeout: '1000',
    showOtpModal: false,
    loading1: true,
    loading2: true,
    loading3: true,
    time: 300,
    rules: {
      required: (value) => !!value || "Required.",
      counter: (value) => value.length <= 4 || "Max 4 characters",
    },
    delivery_man_id: null,
    pendingDelivery: [],
    pickedDelivery: [],
    completedDelivery: [],
    headers: [
      {
        text: "Invoice ID",
        value: "invoice_id",
        sortable: false,
        divider: true,
        // class: "custom-header black--text",
      },
      {
        text: "Payment Status",
        value: "payment_method",
        sortable: false,
        divider: true,
        // align: "start",
        // class: "custom-header black--text",
      },
      {
        text: "Pick Up",
        value: "hub_address.location",
        sortable: false,
        divider: true,
        // class: "custom-header black--text",
        // align: "center",
      },
      {
        text: "Drop Off",
        value: "billing_address.address",
        divider: true,
        sortable: false,
        // class: "custom-header black--text",
      },
      {
        text: "Action",
        value: "actions",
        divider: true,
        sortable: false,
        // value: "custom_date",
        // class: "custom-header black--text",
      },
    ],
    otp: "",
    user_phone_number: null,
    order_id: null,

    otpErrorSnackBar: false,
    otpSuccessSnackBar: false,
  }),
  methods: {
    sendOtp(id, phone_number){
      this.passValues(id, phone_number);
      axios.post('hub/send_otp/', {
        phone: phone_number,
        user_id: this.delivery_man_id,
        order_id: id
      }).then((response) => {
        // console.log(response);
      })
    },
    start() {
      let timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          // this.showOtpModal = false;
          // this.snackbarMessage = "Time up !!!";
          // this.snackbar = true;
          clearInterval(timer);
        }
      }, 1000);
    },
    passValues(id, phone_number){
      this.user_phone_number = phone_number;
      this.order_id = id;
      this.showOtpModal = true;
    },
    confirmOtp(){
      axios.get('hub/validate_otp/' + this.otp + '/' + this.user_phone_number + '/' + this.delivery_man_id + '/' + this.order_id + '/').then((response) => {
        if(response.data.success == false){
          this.otpErrorSnackBar = true;
        } else if (response.data.success == true) {
          this.otpSuccessSnackBar = true;
          this.showOtpModal = false;
        }
        this.initialize();
      })
    },
    showDetails(id){
      this.$router.push('/deliverydashboard/pages/deliverydetails/'+ id )
    },
    pick_order(order_id){
      axios.get('hub/change_delivery_status/'+ order_id +'/'+ this.delivery_man_id +'/').then((response) =>{
         if(response.data.success == false){
          this.snackbar1 = true;
        }
        this.initialize();
      })
    },
    initialize() {
      axios.get("Cart/show_delivery_boy_pending_orders/" + this.delivery_man_id + "/").then((response) => {
        this.pendingDelivery = response.data.data;
        this.loading1 = false;
      });
      axios.get("Cart/show_delivery_boy_picked_orders/" + this.delivery_man_id + "/").then((response) => {
        this.pickedDelivery = response.data.data;
        this.loading2 = false;
      });
      axios.get("Cart/show_delivery_boy_delivered_orders/" + this.delivery_man_id + "/").then((response) => {
        this.completedDelivery = response.data.data;
        this.loading3 = false;
      });
    }
  },
  created(){
    let user = JSON.parse(this.$store.state.user)
    this.delivery_man_id = user.user_id;
    this.initialize();
  },


      computed: {
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },
    filters: {
    prettify: function (value) {
      let data = value.split(":");
      let minutes = data[0];
      let secondes = data[1];
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (secondes < 10) {
        secondes = "0" + secondes;
      }
      return minutes + ":" + secondes;
    },
  },


}
</script>


<style>
.custom-header {
  background-color: #e5f1fb;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>

