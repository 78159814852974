var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-10",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-auto",staticStyle:{"width":"96%"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-tabs',{attrs:{"background-color":"#1e6f5c","active-class":"white black--text","height":"60","dark":""}},[_c('v-tab',[_vm._v("Pending Delivery")]),_c('v-tab',[_vm._v("Picked Delivery")]),_c('v-tab',[_vm._v("Completed Delivery")]),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"my-5",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.pendingDelivery,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showDetails(item.invoice_id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]),(item.delivery_status = 'Pending')?_c('v-btn',{staticClass:"mx-2",attrs:{"dark":"","small":"","color":"#495464"},on:{"click":function($event){return _vm.pick_order(item.id)}}},[_vm._v("PICK")]):_vm._e(),(item.delivery_status != 'Pending')?_c('v-btn',{staticClass:"green",attrs:{"dark":"","small":""}},[_vm._v("CONFIRMED")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Show Details")])])]}}])})],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"my-5",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.pickedDelivery,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showDetails(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]),_c('v-btn',{attrs:{"dark":"","small":"","color":"#91c788"},on:{"click":function($event){_vm.sendOtp(item.id, item.phone_number); _vm.start()}}},[_vm._v("COMPLETE")])]}}],null,true)},[_c('span',[_vm._v("Show Details")])])]}}])})],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"my-5",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.completedDelivery,"items-per-page":10,"loading":_vm.loading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showDetails(item.invoice_id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Show Details")])])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar1 = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar1),callback:function ($$v) {_vm.snackbar1=$$v},expression:"snackbar1"}},[_vm._v(" Something Went Wrong! ")]),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.otpErrorSnackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.otpErrorSnackBar),callback:function ($$v) {_vm.otpErrorSnackBar=$$v},expression:"otpErrorSnackBar"}},[_vm._v(" Invalid OTP ")]),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"green","text":""},on:{"click":function($event){_vm.otpSuccessSnackBar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.otpSuccessSnackBar),callback:function ($$v) {_vm.otpSuccessSnackBar=$$v},expression:"otpSuccessSnackBar"}},[_vm._v(" Successful OTP ")]),_c('v-dialog',{attrs:{"width":"400","persistent":_vm.time > 0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.showOtpModal),callback:function ($$v) {_vm.showOtpModal=$$v},expression:"showOtpModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[(_vm.time > 0)?_c('p',{staticClass:"mb-0 text-body-1"},[_vm._v(" Enter your OTP sent to "),_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.user_phone_number))])]):_c('p',{staticClass:"mb-0 text-body-1"},[_vm._v("Time Up !!")])]),(_vm.time > 0)?_c('div',[_c('v-card-text',{staticClass:"px-3 pb-0"},[_c('v-form',[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"center"}},[_c('v-text-field',{staticClass:"ml-2",staticStyle:{"max-width":"50px"},attrs:{"label":"M-","solo":"","disabled":""}}),_c('v-text-field',{staticClass:"ml-3",staticStyle:{"max-width":"150px"},attrs:{"rules":[_vm.rules.required, _vm.rules.counter],"outlined":"","height":"48px"},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})],1)],1),_c('div',{staticClass:"timer d-flex flex-row"},[_c('p',{staticClass:"font-weight-bold"},[_vm._v("Time Left :")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("prettify")(_vm.prettyTime)))])])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"color":"#FF7743","dark":"","elevation":"0"},on:{"click":function($event){return _vm.confirmOtp()}}},[_vm._v(" Verify ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }